.mint {
    .wallet {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
        padding: 0.8rem 1.2rem;
        border: 2px solid #4989A7;
        border-radius: 16px;
        background: #fff;
        position: absolute;
        top: 50px;
        right: 50px;
        z-index: 10;
        color: #4989A7;

        .btn-img {
            cursor: pointer;
        }
    }

    .text-content {
        min-width: 78%;
        padding-top: 4rem;
        padding-bottom: 4rem;
        position: relative;
        max-height: 85%;

        .bg-video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .overlay {
            height: 100%;
            width: 100%;
            background: rgba($color: #000000, $alpha: 0.4);
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

@media (max-width: 768px) {
    .mint {

        .wallet {
            right: 20px;
            top: 20px;
        }

        .text-content {
            padding: 2rem;
            overflow: visible;
        }
    }
}