.nft {
    position: relative;
    z-index: 9;
    max-width: 300px;
    margin: auto;
    .mint-img {
        width: 100%;
        object-fit: cover;
        margin-bottom: 3rem;
        border-radius: 16px;
    }

    .counter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #fff;
        border-radius: 16px;
        padding: 1rem 1.5rem;
        color: #fff;
        margin-bottom: 1rem;

        img {
            width: 20px;
        }

        p {
            font-size: 1.3rem;
            font-weight: bold;
        }

        &.total {
            justify-content: center;
            border: 2px solid #fff;

            p {
                font-size: 1rem;
                font-weight: normal;
            }
        }

        .btn-img {
            cursor: pointer;
        }
    }


    .caution {
        text-align: right;
        color: #fff;
    }

    .primary-btn {
        width: 100%;
        margin-top: 2rem;
    }
}

@media (max-width: 768px) {
    .nft {
        max-width: none;
    }
}