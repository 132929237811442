.back-button {
    position: relative;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #4989A7;
    color: #fff;
    z-index: 9;

    &:hover {
        border: 2px solid #fff;
    }
}