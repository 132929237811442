.home {
    .content {
        position: relative;
        z-index: 9;

        .dock {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            height: 150px;
            align-items: center;
            gap: 2rem;
            padding: 1rem;
        }
    }

    .mint-btn-wrapper {
        position: absolute;
        bottom: 100px;
        left: 0;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .home {
        .content {
            transform: translateY(-100px);

            .dock {
                padding: 0;
            }

            ul {
                padding: 0 2rem;
                gap: 3rem;

                img {
                    max-width: 100px;
                }
            }
        }
    }
}