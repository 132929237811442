@import './reset';

button {
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease;
    overflow: hidden;
}

@keyframes shine {
    0% {
        transform: translateX(-30px) rotate(-25deg);
    }

    100% {
        transform: translateX(250px) rotate(-25deg);
    }
}

.primary-btn {
    position: relative;
    background-color: #4989A7;
    padding: 1rem 4rem;
    font-size: 1.3rem;
    box-shadow: 0px 2px 24px 0px #0000001A;
    border-radius: 8px;
    color: #fefefe;
    margin-top: 80px;

    &::after {
        content: '';
        display: block;
        width: 40px;
        height: 100px;
        top: -20px;
        left: 0;
        background: rgba(255, 255, 255, .3);
        transform: translateX(-70px) rotate(-25deg);
        position: absolute;
        transition: .5s;
    }

    &:hover {
        transform: scale(0.95);
    }

    &:hover::after {
        transform: translateX(320px) rotate(-25deg);
    }

    &.loading {
        background-color: #4989a781;
        pointer-events: none;

        .loading-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            .bar {
                background-color: #72b3d0;
                min-width: 100%;
                min-height: 5px;
                position: relative;
                overflow: hidden;
            }

            .bar::after {
                content: "";
                min-height: 5px;
                position: absolute;
                background: #fff;
                transform: translateX(-100%);
                animation: animate 2s infinite;
            }

            .bar::before {
                content: "";
                min-height: 5px;
                position: absolute;
                background: #fff;
                transform: translateX(-100%);
                animation: animate 2s infinite;
                animation-delay: 1s;
            }

            @keyframes animate {
                0% {
                    transform: translateX(-150%);
                    min-width: 100px;
                }

                100% {
                    transform: translateX(300%);
                    min-width: 400px;
                }
            }
        }
    }
}

.tooltip-container {
    position: relative;
    z-index: 9999999 !important;
    background-color: #fff !important;
    color: #000 !important;
    border: 1px solid #e0e0e0 !important;
    border-radius: 6px !important;
    box-shadow: 0px 12px 24px 0px rgba(85, 115, 160, 0.10), 0px 1px 2px 0px #BDABCF, 0px 0px 0px 1px #A39DC3 !important;
}

.justify-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.page {
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: #BDABCF;
    padding: 0 5rem;
    overflow: hidden;

    .button-container {
        position: absolute;
        top: 50px;
        left: 50px;
    }

    .bg-pattern {
        height: 100vh;
        width: 100vw;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    .text-content {
        position: relative;
        z-index: 9;
        max-width: 85%;
        margin: auto;
        background-color: #4989A7;
        overflow: hidden;

        header {
            padding: 1.8rem;
            color: #fff;

            h1 {
                font-size: 2.5rem;
                text-transform: uppercase;
                margin-bottom: 2rem;
            }

            p {
                font-size: 1.8rem;
                margin-bottom: 4rem;
            }
        }

        .content-pattern {
            width: 100%;
        }
    }
}

.logo-container {
    width: 100%;
    display: flex;
    gap: 1rem;
    margin-bottom: 3rem;

    img {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .logo-container {
        gap: 0.31rem;
        margin-bottom: 3rem;
    }

    .page {
        min-height: 100vh;
        height: auto;
        padding: 6rem 1rem 3rem;

        .button-container {
            position: absolute;
            top: 20px;
            left: 20px;
        }

        .text-content {
            max-width: 95%;

            header {
                h1 {
                    font-size: 2rem;
                }

                p {
                    font-size: 1.5rem;
                }
            }
        }
    }

    .dock-img {
        width: 80px !important;
    }

    .primary-btn {
        padding: 1rem 2rem;
        font-size: 1.2rem;
    }
}