.mint-card {
    position: relative;
    z-index: 9;
    max-width: 500px;
    min-height: 500px;
    background-color: #BDABCF;
    overflow: hidden;
    box-shadow: 0px 4px 4px -1px #00000040;
    border-radius: 16px;

    .grid {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .content {
        position: relative;
        z-index: 9;

        &.wallet-not-connected {
            padding: 10rem 0 5rem;

            .marquee {
                margin-bottom: 10rem;
            }

            .marquee-content {
                display: flex;
                animation: scrolling 10s linear infinite;
            }

            .marquee-item {
                flex: 0 0 16vw;
                margin: 0 1vw;
            }

            .marquee-item img {
                display: block;
                width: 800px;
            }

            @keyframes scrolling {
                0% {
                    transform: translateX(0);
                }

                100% {
                    transform: translatex(-50vw);
                }
            }

            p {
                color: #fff;
                font-size: 1.5rem;
                text-align: center;
                max-width: 90%;
                margin: auto;
            }
        }

        &.wallet-connected {
            padding: 4rem;
        }
    }
}


@media (max-width: 768px) {
    .mint-card {
        max-width: none;
        min-height: auto;

        .content {
            &.wallet-not-connected {
                padding: 4rem 0 2rem;

                p {
                    font-size: 1rem;
                }

                .marquee-item img {
                    width: 500px;
                }
            }

            @keyframes scrolling {
                0% {
                    transform: translateX(0);
                }

                100% {
                    transform: translatex(-280vw);
                }
            }

            &.wallet-connected {
                padding: 1rem;
            }
        }
    }
}